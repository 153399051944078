import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import ProgressBar from "react-scroll-progress-bar";

import { Layout } from "../components";
import FormBlog from "../components/FormBlog";
import BanerMDD from "../components/BanerMDD/BanerMDD";

import locales from "../constants";

const getColorByCategory = (category) => {
  switch (category) {
    case "biznes":
      return "#00319F";
    case "branding":
      return "#b90000";
    case "marketing internetowy":
    case "marketing-internetowy":
      return "#7713c0";
    case "sklepy internetowe":
    case "sklepy-internetowe":
      return "#02b7b7";
    case "social media":
    case "social-media":
      return "#f163c7";
    case "StartUp":
    case "startup":
      return "#add601";
    case "strony internetowe":
    case "strony-internetowe":
      return "#c9c902";
    case "szkolenia":
      return "#d98503";
    case "UX":
    case "ux":
      return "#b700a9";
    default:
      return "#2D2D2D";
  }
};

const getCategoryName = (string) => {
  switch (string) {
    case "biznes":
      return "Biznes";
    case "branding":
      return "Branding";
    case "marketing internetowy":
    case "marketing-internetowy":
      return "Marketing internetowy";
    case "sklepy internetowe":
    case "sklepy-internetowe":
      return "Sklepy internetowe";
    case "social media":
    case "social-media":
      return "Social media";
    case "StartUp":
    case "startup":
      return "StartUp";
    case "strony internetowe":
    case "strony-internetowe":
      return "Strony internetowe";
    case "szkolenia":
      return "Szkolenia";
    case "UX":
    case "ux":
      return "UX";
    default:
      return null;
  }
};

const Post = ({ pageContext, location }) => {
  const [showWidget, setShowWidget] = useState(false);

  const lang = "pl";

  const {
    id,
    title,
    content,
    author,
    date,
    categories,
    seo,
    showAuthor,
    showMdd,
    contactWidget,
    heroImg,
    lastPosts,
  } = pageContext.data;

  const last_posts_filtered = lastPosts.edges.filter((el) => el.node.id !== id);

  const data = useStaticQuery(graphql`
    {
      allWordpressWpUsers {
        nodes {
          acf {
            user_company_role
            contact_number
          }
          name
          id
        }
      }
    }
  `);

  useEffect(() => {
    $(".aligncenter").parent().attr("target", "_blank").addClass("media-image");
  });

  useEffect(() => {
    const switchWidget = () => {
      const windowOffsetBottom = document.documentElement.scrollHeight - 2400;
      if (window.scrollY > 1400 && window.scrollY < windowOffsetBottom) {
        setShowWidget(true);
      } else {
        setShowWidget(false);
      }
    };

    window.addEventListener("scroll", switchWidget);

    return () => {
      window.removeEventListener("scroll", switchWidget);
    };
  }, []);

  const chooseColorByMainCategory = () => {
    const main_category = pageContext?.data?.path.split("/")?.[2];
    const main_color = getColorByCategory(main_category);
    return main_color;
  };

  const getContactPersonData = (contact_user) => {
    const user = data?.allWordpressWpUsers?.nodes?.filter(
      (el) => el?.name === contact_user
    );
    if (user?.[0]?.acf) {
      return {
        contact_number: user?.[0]?.acf?.contact_number || "",
        user_company_role: user?.[0]?.acf?.user_company_role || "",
      };
    }
    return null;
  };

  return (
    <Layout
      seo={{
        ...seo,
        title: title,
        lang: "pl",
      }}
    >
      <section
        className="container-fluid px-0 post__hero"
        style={{
          background: chooseColorByMainCategory(),
        }}
      >
        <div className="post__hero-image">
          <img src={heroImg?.source_url} alt={heroImg?.alt_text} />
        </div>
        <div
          className="post__hero-overlay"
          style={{
            background: heroImg ? chooseColorByMainCategory() : "#2D2D2D",
          }}
        ></div>
        <div className="post__hero-content">
          <div className="post__hero-date">
            {date?.split("T")[0].split("-").reverse().join(".")}
          </div>
          <div className="post__hero-categories">
            {categories?.map((category, index) => (
              <p
                className="post__hero-category"
                key={index}
                style={{
                  background: getColorByCategory(category?.name),
                }}
              >
                {category?.name}
              </p>
            ))}
          </div>
          <p
            className="post__hero-title"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </div>
      </section>

      <div className="progress_wrapper">
        <ProgressBar height="6px" bgcolor="#3d00a5" duration="0.2" />
      </div>

      <section className="container-fluid post__breadcrumbs">
        <Link to="/">Strona główna</Link> / <Link to="/blog/">Blog</Link> /{" "}
        <Link to={`/${pageContext?.data?.path.split("/")?.[2]}#list`}>
          {getCategoryName(pageContext?.data?.path.split("/")?.[2])}
        </Link>{" "}
        /{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </section>

      <section className="container-fluid post__main">
        {showAuthor === true && (
          <aside className="post__author">
            <p className="post__author-intro">Autor</p>
            <div className="post__author-info">
              <div className="post__author-image">
                <img
                  src={
                    author.image
                      ? author.image.full.source_url
                      : author.placeholder.url
                  }
                  alt={author.name}
                />
              </div>
              <div className="post__author-details">
                <h3>{author.name}</h3>
                {author.acf ? <h4>{author.acf.user_company_role}</h4> : ""}
              </div>
            </div>
            <div className="post__author-desc">
              <p
                dangerouslySetInnerHTML={{
                  __html: author.description,
                }}
                rel="author"
              />
            </div>
          </aside>
        )}

        <div
          className={`post__content--wrapper${showAuthor ? "" : " no-author"}`}
        >
          <div className="post__content">
            {/* <h1 dangerouslySetInnerHTML={{ __html: title }} /> */}
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <ul className="post__social-list">
            <li>Podziel się artykułem:</li>
            <li>
              <FacebookShareButton url={location.href}>
                <FacebookIcon size={32} />
              </FacebookShareButton>
            </li>
            <li>
              <TwitterShareButton url={location.href}>
                <TwitterIcon size={32} />
              </TwitterShareButton>
            </li>
            <li>
              <LinkedinShareButton url={location.href}>
                <LinkedinIcon size={32} />
              </LinkedinShareButton>
            </li>
          </ul>

          {showAuthor === true && (
            <article className="post__author--mobile">
              <div className="post__author-image">
                <img
                  src={
                    author.image
                      ? author.image.full.source_url
                      : author.placeholder.url
                  }
                  alt={author.name}
                />
              </div>
              <div className="post__author-desc">
                <h3>{author.name}</h3>
                {author.acf ? <h4>{author.acf.user_company_role}</h4> : ""}

                <div
                  dangerouslySetInnerHTML={{
                    __html: author.description,
                  }}
                  rel="author"
                />
              </div>
            </article>
          )}

          {showMdd === true && <BanerMDD type="post" />}

          <div className="post__contact-section">
            <div className="post__contact-heading">
              <h3>Zainteresował Cię ten artykuł?</h3>
              <h3>Porozmawiajmy</h3>
            </div>
            <div className="post__contact-form">
              <FormBlog
                locales={locales[lang]}
                lang={lang}
                landing_page={`Blog post - ${title}`}
              />
            </div>
          </div>
        </div>

        {contactWidget && (
          <aside className={`post__widget${showWidget ? " visible" : ""}`}>
            <h2 className="post__widget-intro">
              Porozmawiajmy
              <br /> o Twoim projekcie
            </h2>
            {contactWidget.user_avatar && (
              <div
                className="post__widget-image"
                dangerouslySetInnerHTML={{
                  __html: contactWidget?.user_avatar,
                }}
              />
            )}
            <h3>{contactWidget?.display_name}</h3>
            <p>
              {
                getContactPersonData(contactWidget?.display_name)
                  ?.user_company_role
              }
            </p>
            <a
              href={`tel:${
                getContactPersonData(contactWidget?.display_name)
                  ?.contact_number
              }`}
            >
              {
                getContactPersonData(contactWidget?.display_name)
                  ?.contact_number
              }
            </a>
            <a href={`mailto:${contactWidget?.user_email}`}>
              {contactWidget?.user_email}
            </a>
            <Link to="/kontakt/" className="btn_custom">
              Skontaktuj się
            </Link>
          </aside>
        )}
      </section>

      <section className="container-fluid post__grid">
        <div className="row post__grid-posts">
          {last_posts_filtered?.slice(0, 3)?.map(({ node }) => {
            const main_category = node?.path.split("/")?.[2];
            const main_color = getColorByCategory(main_category);

            return (
              <article
                key={node.id}
                className="col-12 offset-sm-2 col-sm-8 offset-lg-0 col-lg-4 post__grid-item"
              >
                <Link
                  to={node?.path}
                  className="post__grid-link"
                  style={{
                    background: main_color,
                  }}
                >
                  {node?.featured_media?.source_url && (
                    <div className="post__grid-image">
                      <img
                        src={node?.featured_media?.source_url}
                        alt={node?.featured_media?.alt_text}
                      />
                    </div>
                  )}
                  <div className="post__grid-content">
                    <time className="post__grid-date">
                      {node?.date?.split("T")[0].split("-").reverse().join(".")}
                    </time>
                    <div className="post__grid-desc">
                      {node?.categories?.map((category, index) => (
                        <span
                          key={index}
                          className="post__grid-label"
                          style={{
                            background: getColorByCategory(category?.name),
                          }}
                        >
                          {category?.name}
                        </span>
                      ))}
                      <p
                        className="post__grid-title"
                        dangerouslySetInnerHTML={{ __html: node.title }}
                      />
                    </div>
                  </div>
                </Link>
              </article>
            );
          })}
        </div>
      </section>
    </Layout>
  );
};

export default Post;
