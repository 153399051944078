/* eslint-disable no-unused-vars */

import "./styles.scss";

import React, { useState } from "react";
import useForm from "react-hook-form";
import Confetti from "react-dom-confetti";

import { Input, Loader } from "../../components";
import BlogCheckbox from "./components/BlogCheckbox";

const FormBlog = ({ locales, landing_page }) => {
  const { register, errors, handleSubmit, triggerValidation } = useForm({
    mode: "onChange",
  });
  const config = {
    angle: 90,
    spread: 100,
    startVelocity: 60,
    elementCount: 150,
    dragFriction: 0.1,
    duration: 2000,
    stagger: 0,
    width: "7px",
    height: "10px",
    colors: [
      "#E68F17",
      "#FAB005",
      "#FA5252",
      "#E64980",
      "#BE4BDB",
      "#0B7285",
      "#15AABF",
      "#EE1233",
      "#40C057",
    ],
  };
  const [isLoading, setLoading] = useState(false);
  const [showConfetti, triggerConfetti] = useState(false);

  const onSubmit = (data, e) => {
    if (data.bot_field) return false;
    delete data.bot_field;
    data = { ...data };
    triggerConfetti(true);
    // setTimeout(() => {
    //   setLoading(true);
    //   axios
    //     .post(
    //       "https://adream-mailing.herokuapp.com/blog-form",
    //       data
    //     )
    //     .then((res) => {
    //       setLoading(false);
    //       triggerConfetti(false);
    //       e.target.reset();
    //       navigate("/landing-pages-kontakt/");
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //     });
    // }, 1500);
  };
  return (
    <div className={`blog-form wpcf7`}>
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="checkbox"
          name="bot_field"
          ref={register}
          className="bot-field"
        />
        <Input
          type="text"
          name="landing_page"
          value={landing_page}
          register={register}
          hidden
        />
        <Input
          type="text"
          name="name"
          label="Imię i nazwisko"
          register={register}
          validation={{
            required: locales.required,
            message: locales.email_error,
          }}
          errors={errors}
        />
        <Input
          type="phone"
          name="phone"
          label={locales.phone}
          register={register}
          validation={{
            required: locales.required,
            message: locales.email_error,
          }}
          errors={errors}
        />
        <Input
          type="email"
          name="email"
          label="E-mail"
          register={register}
          validation={{
            required: locales.required,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
              message: locales.email_error,
            },
          }}
          errors={errors}
        />

        <BlogCheckbox
          register={register}
          name="term1"
          size="sm"
          validation={{ required: locales.required }}
          errors={errors}
          label={locales.audyt_form.audit_legal}
          label_short={`${
            locales.audyt_form.audit_legal.substring(0, 120) + "..."
          }`}
          white
          show_more_btn
        />
        <div className="btn_over_wrapper">
          <div className="btn_wrapper">
            <input
              type="submit"
              value={locales.contact_form_send}
              className="wpcf7-form-control wpcf7-submit"
              onClick={() => {
                triggerValidation();
              }}
            />
            <Confetti active={showConfetti} config={config} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormBlog;
