import React, { useState } from "react";

const BlogCheckbox = ({
  type,
  name,
  label_short,
  label,
  size,
  register,
  errors,
  validation,
  required,
  white,
  show_more_btn,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={`check-group ${size ? size : ""} ${
        errors && errors[name] ? "has-error" : ""
      } ${white ? `check-group--white` : ""}`}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        ref={register(validation || {})}
        required={required}
      />
      <label htmlFor={name}>
        {showMore ? label : label_short}
        {validation && <sup>*</sup>}
        {show_more_btn && !showMore && (
          <button
            type="button"
            className="check-group__show-more"
            onClick={() => setShowMore(true)}
          >
            Więcej
          </button>
        )}
        {errors && errors[name] && (
          <span role="alert" className="error">
            {errors[name].message}
          </span>
        )}
      </label>
    </div>
  );
};

export default BlogCheckbox;
